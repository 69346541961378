<template>
  <a
    :href="href"
    target="_blank"
    class="mx-1"
  >
    <v-img
      :alt="href"
      :src="badge"
      transition="scale-transition"
      height="30"
      :width="type === 'AppStore' ? 90 : 100"
    />
  </a>
</template>

<script>
export default {
  name: 'Badge',
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      apps: {
        AppStore: {
          href: 'https://itunes.apple.com/app/idnow-video-ident/id918081242',
          badge: 'AppStore.svg',
        },
        GooglePlay: {
          href: 'https://play.google.com/store/apps/details?id=de.idnow',
          badge: 'GooglePlay.svg',
        },
      },
    };
  },
  computed: {
    href() {
      return this.apps[this.type].href;
    },
    badge() {
      return require(`@/assets/badges/${this.apps[this.type].badge}`);
    },
  },
};
</script>
